import * as types from "./ErrorTypes";

// Constants
import { ERROR_TYPES } from "../../utils/Constants";

const INITIAL_STATE = {
  showAs: "dialog",
  show: false,
  message: null,
  title: "Whoops !!",
  type: ERROR_TYPES.DEFAULT,
  noAutoClose: false,
};

const reducer = (state = { ...INITIAL_STATE }, action) => {
  switch (action.type) {
    case types.SET_ERROR:
      return {
        ...state,
        show: true,
        message: action.action.message,
        noAutoClose: action.action.noAutoClose || INITIAL_STATE.noAutoClose,
        title: action.action.title || INITIAL_STATE.title,
        type: action.action.type || INITIAL_STATE.type,
        showAs: action.action.showAs || INITIAL_STATE.showAs,
      };
    case types.RESET_ERROR:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
