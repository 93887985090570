import * as types from "./ErrorTypes";

export const setErrorMessage = ({
  message,
  title = null,
  type = null,
  showAs = null,
  noAutoClose,
}) => {
  console.log("noAutoooooooo", noAutoClose);
  return {
    type: types.SET_ERROR,
    action: {
      message,
      title,
      type,
      showAs,
      noAutoClose,
    },
  };
};

export const resetErrorMessage = () => {
  return {
    type: types.RESET_ERROR,
  };
};
