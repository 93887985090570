import { v4 as uuidv4 } from "uuid";
import * as types from "./CartTypes";

const INITIAL_STATE = {
  hotelKey: null,
  checkoutDetails: {},
  discountType: null,
  data: [],
  priceSummary: {
    subTotal: 0,
    deliveryCharge: 0,
    serviceCharge: 0,
    discount: 0,
    tax: 0,
    total: 0,
  },
  couponLoaded: false,
  coupons: [],
  validatedCoupons: null,
  selectedCoupon: {},
  availableCoupons: [],
  selectedTable: {},
  guestCount: 1,
  isWalletModal: false,
  isDiscountRemoveModal: false,
  isWalletRedeemed: false,
  orderTypeModal: false,
  tipAmount: null,
  isValidating: false,
  coupon_validation_result: null,
  isFreeDeliveryCouponApplied: false,
  freeDeliveryAmountApplied: false,
  delCharge: null,
  userConsent: false,
};

// Replace with you own reducer
const reducer = (state = INITIAL_STATE, { ...action }) => {
  switch (action.type) {
    case types.UPDATE_CART:
      const _index = state.data.findIndex(
        (p) => p._cartItemId === action.payload._cartItemId
      );
      if (_index > -1) {
        action.payload.count === 0
          ? state.data.splice(_index, 1)
          : (state.data[_index].count = action.payload.count);
        if (action?.payload?.modifiers) {
          state.data[_index].modifiers = [...action?.payload?.modifiers];
        }
        state.data[_index].size = action.payload.size;
        state.data[_index].extraComments = action.payload.extraComments;
        state.data[_index].combos = action.payload.combos || {};
        state.data[_index].isWalletRedeemed = action.payload.isWalletRedeemed;
        state.data[_index].walletDiscount = action.payload.walletDiscount;
        state.data[_index].walletAmount = action.payload.walletAmount;
        state.data[_index].walletType = action.payload.walletType;
        state.data[_index].walletUsedForProduct =
          action.payload.walletUsedForProduct;
      } else {
        state.data.push({
          _cartItemId: uuidv4(),
          ...action.payload,
        });
      }
      return {
        ...state,
      };
    case types.DELETE_FROM_CART:
      const _reomveProductIndex = state.data.findIndex(
        (p) => p._cartItemId === action.payload._cartItemId
      );
      if (_reomveProductIndex > -1) {
        state.data.splice(_reomveProductIndex, 1);
      }
      return {
        ...state,
      };
    case types.SAVE_CHECKOUT_DETAILS:
      return {
        ...state,
        checkoutDetails: {
          ...state.checkoutDetails,
          ...action.payload,
        },
      };
    case types.PROCESS_CART:
      const { hotelKey, catrgories } = action.payload;
      const currentDataInCart = state.data.slice();
      const updatedDataInCart = [];

      if (state.hotelKey !== hotelKey) {
      } else if (currentDataInCart.length > 0) {
        for (let i = 0; i < currentDataInCart.length; i++) {
          if (currentDataInCart[i].combo_id && catrgories?.combos?.length > 0) {
            const selectedItem = catrgories?.combos?.find(
              (it) => it.combo_id === currentDataInCart[i].combo_id
            );

            if (selectedItem) {
              updatedDataInCart.push({
                ...currentDataInCart[i],
                // ...selectedItem,
              });
            }
          } else {
            if (catrgories?.data?.length > 0) {
              const selectedCategory = catrgories?.data?.find(
                (c) => c.categoryId === currentDataInCart[i].categoryId
              );
              const selectedItem = selectedCategory?.item.find(
                (it) => it.itemId === currentDataInCart[i].itemId
              );
              if (selectedItem) {
                updatedDataInCart.push({
                  ...currentDataInCart[i],
                  ...selectedItem,
                });
              }
            }
          }
        }
      }
      return {
        ...state,
        hotelKey,
        data: updatedDataInCart,
      };
    case types.CLEAR_CART:
      return {
        ...state,
        checkoutDetails: {},
        data: [],
        priceSummary: {
          subTotal: 0,
          deliveryCharge: 0,
          serviceCharge: 0,
          discount: 0,
          tax: 0,
          total: 0,
          walletAmount: 0,
        },
        tipAmount: null,
      };
    case types.PRICE_SUMMARY:
      return {
        ...state,
        priceSummary: { ...state.priceSummary, ...action.payload },
      };
    case types.GET_COUPON_REQUEST:
      return {
        ...state,
        couponLoaded: false,
      };
    case types.SET_CONSENT:
      return {
        ...state,
        userConsent: action.payload,
      };
    case types.SET_DEL_CHARGE:
      return {
        ...state,
        delCharge: action.payload,
      };
    case types.IS_FREE_DELIVERY_COUPON_APPLIED:
      return {
        ...state,
        isFreeDeliveryCouponApplied: action.payload,
      };
    case types.IS_COUPON_VALIDATING:
      return {
        ...state,
        isValidating: action.payload,
      };
    case types.SET_COUPON_VALIDATION_RESULT:
      return {
        ...state,
        coupon_validation_result: action.payload,
      };
    case types.GET_COUPON_RECEIVE:
      return {
        ...state,
        coupons: action.payload,
        couponLoaded: true,
      };
    case types.SET_TIP_AMOUNT:
      return {
        ...state,
        tipAmount: action.payload,
      };
    case types.CLEAR_TIP_AMOUNT:
      return {
        ...state,
        tipAmount: null,
      };
    case types.SELECT_COUPON:
      return {
        ...state,
        selectedCoupon: action.payload || {},
      };
    case types.DISCOUNT_REMOVE_MODAL:
      return {
        ...state,
        isDiscountRemoveModal: action.payload,
      };
    case types.SET_DISCOUNT_TYPE:
      return {
        ...state,
        discountType: action.payload,
      };
    case types.CLEAR_COUPON:
      return {
        ...state,
        selectedCoupon: {},
        coupon_validation_result: false,
        isFreeDeliveryCouponApplied: false,
        validatedCoupons: null,
        freeDeliveryAmountApplied: false,
      };
    case types.WALLET_MODAL:
      return {
        ...state,
        isWalletModal: action.payload,
      };
    case types.FREE_DELIVERY_AMOUNT_APPLIED:
      return {
        ...state,
        freeDeliveryAmountApplied: action.payload,
      };
    case types.SET_AVAILABLE_COUPONS:
      return {
        ...state,
        availableCoupons: action.payload || [],
      };
    case types.SET_VALIDATED_COUPON:
      return {
        ...state,
        validatedCoupons: action.payload,
      };
    case types.REMOVE_VALIDATED_COUPON:
      return {
        ...state,
        validatedCoupons: null,
        freeDeliveryAmountApplied: false,
      };
    case types.SET_ORDER_TYPE_MODAL:
      return {
        ...state,
        orderTypeModal: action.payload,
      };
    case types.CLEAR_AVAILABLE_COUPONS:
      return {
        ...state,
        availableCoupons: [],
      };
    case types.SELECT_TABLE:
      return {
        ...state,
        selectedTable: action.payload || {},
      };
    case types.CLEAR_DISCOUNT_TYPE:
      return {
        ...state,
        discountType: null,
      };
    case types.CLEAR_TABLE_SELECTION:
      return {
        ...state,
        selectedTable: {},
      };
    case types.SET_WALLET_REDEEMED:
      return {
        ...state,
        isWalletRedeemed: action.payload,
      };
    case types.SET_GUESTS_COUNT:
      return {
        ...state,
        guestCount: action.payload || 1,
      };
    default:
      return state;
  }
};

export default reducer;
