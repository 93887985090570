// Replace with your request types
export const UPDATE_CART = "UPDATE_CART";
export const PROCESS_CART = "PROCESS_CART";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const SAVE_CHECKOUT_DETAILS = "SAVE_CHECKOUT_DETAILS";
export const CLEAR_CART = "CLEAR_CART";
export const PRICE_SUMMARY = "PRICE_SUMMARY";
export const GET_COUPON_REQUEST = "GET_COUPON_REQUEST";
export const GET_COUPON_RECEIVE = "GET_COUPON_RECEIVE";
export const SELECT_COUPON = "SELECT_COUPON";
export const CLEAR_COUPON = "CLEAR_COUPON";
export const SET_AVAILABLE_COUPONS = "SET_AVAILABLE_COUPONS";
export const CLEAR_AVAILABLE_COUPONS = "CLEAR_AVAILABLE_COUPONS";
export const VALIDATE_COUPON = "VALIDATE_COUPON";
export const SELECT_TABLE = "SELECT_TABLE";
export const CLEAR_TABLE_SELECTION = "CLEAR_TABLE_SELECTION";
export const SET_GUESTS_COUNT = "SET_GUESTS_COUNT";
export const SET_DISCOUNT_TYPE = "SET_DISCOUNT_TYPE";
export const CLEAR_DISCOUNT_TYPE = "CLEAR_DISCOUNT_TYPE";
export const WALLET_MODAL = "WALLET_MODAL";
export const DISCOUNT_REMOVE_MODAL = "DISCOUNT_REMOVE_MODAL";
export const SET_WALLET_REDEEMED = "SET_WALLET_REDEEMED";
export const SET_ORDER_TYPE_MODAL = "SET_ORDER_TYPE_MODAL";
export const SET_TIP_AMOUNT = "SET_TIP_AMOUNT";
export const CLEAR_TIP_AMOUNT = "CLEAR_TIP_AMOUNT";
export const IS_COUPON_VALIDATING = "IS_COUPON_VALIDATING";
export const SET_COUPON_VALIDATION_RESULT = "SET_COUPON_VALIDATION_RESULT";
export const SET_VALIDATED_COUPON = "SET_VALIDATED_COUPON";
export const REMOVE_VALIDATED_COUPON = "REMOVE_VALIDATED_COUPON";
export const IS_FREE_DELIVERY_COUPON_APPLIED =
  "IS_FREE_DELIVERY_COUPON_APPLIED";
export const FREE_DELIVERY_AMOUNT_APPLIED = "FREE_DELIVERY_AMOUNT_APPLIED";
export const SET_DEL_CHARGE = "SET_DEL_CHARGE";
export const SET_CONSENT = "SET_CONSENT";
