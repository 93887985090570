import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
// Material
import Typography from "@material-ui/core/Typography";
// Icons
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
// Styles
import useStyles from "./HotelDetails.style";
import Modal from "../../components/Material/Modal/Modal";
import { Menu, MenuItem, Paper, useMediaQuery } from "@material-ui/core";
import { ArrowDropDownCircleOutlined } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import BranchHotels from "../BranchHotels/BranchHotels";
import { useTheme } from "@material-ui/styles";
// Controls
// import IconBtn from "./../Controls/IconButton/IconButton";

const HotelDetails = ({
  desc = false,
  color = "white",
  darkTheme,
  isCart = false,
  isHome = false,
  dropdownAnchor = false,
  setDropdownAnchor,
}) => {
  const classes = useStyles();
  // const { theme } = useSelector((state) => state?.theme);
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const history = useHistory();
  const { hash } = location;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dropdownRef = useRef(null);
  const arrowButtonRef = useRef(null);
  const idFromLink = hash.substring(1);
  // const [dropdownAnchor, setDropdownAnchor] = useState(null);
  const isDropdownOpen = Boolean(dropdownAnchor);
  const { hotel, linked_rest } = useSelector((state) => state.hotel);
  const hotelAddress = useMemo(() => {
    if (hotel) {
      const address = hotel.address[0];
      return `${address.streetName}, ${address.city}, ${address.country}, ${address.postalCode}`;
    }
    return "";
  }, [hotel]);

  // const linked_rest = [
  //   {
  //     id: 8,
  //     name: "test",
  //     address: [
  //       {
  //         streetName: "High Street",
  //         postalCode: "5010",
  //         city: "Wellington",
  //         country: "New Zealand",
  //       },
  //     ],
  //     address_full: "Rangiora 2324",
  //     online_key: "3902890",
  //   },
  //   {
  //     id: 40,
  //     name: "Port Muziris",
  //     address: [
  //       {
  //         streetName: "High Street",
  //         postalCode: "5010",
  //         city: "Wellington",
  //         country: "New Zealand",
  //       },
  //     ],
  //     address_full: "higt street 2324",
  //     online_key: "9929382",
  //   },
  //   {
  //     id: 16,
  //     name: "test 1121212",
  //     address: [
  //       {
  //         streetName: "High Street",
  //         postalCode: "5010",
  //         city: "Wellington",
  //         country: "New Zealand",
  //       },
  //     ],
  //     address_full: "ontarioo 2324",
  //     online_key: "3902890",
  //   },
  //   {
  //     id: 44,
  //     name: "Port Muziris dev",
  //     address: [
  //       {
  //         streetName: "High Street",
  //         postalCode: "5010",
  //         city: "Wellington",
  //         country: "New Zealand",
  //       },
  //     ],
  //     address_full: "kochi 2324",
  //     online_key: "9927382",
  //   },
  //   {
  //     id: 18,
  //     name: "test high",
  //     address: [
  //       {
  //         streetName: "High Street",
  //         postalCode: "5010",
  //         city: "Wellington",
  //         country: "New Zealand",
  //       },
  //     ],
  //     address_full: "kottayam 2324",
  //     online_key: "3902890",
  //   },
  //   {
  //     id: 30,
  //     name: "Port Muziris street",
  //     address: [
  //       {
  //         streetName: "High Street",
  //         postalCode: "5011",
  //         city: "Wellington",
  //         country: "New Zealand",
  //       },
  //     ],
  //     address_full: "pala 2324",
  //     online_key: "9929982",
  //   },
  // ];

  function isMobile() {
    return window.innerWidth <= 768; // You can adjust the breakpoint as needed
  }
  // console.log("theme", theme);
  const handleDropdownToggle = () => {
    setDropdownAnchor(!dropdownAnchor);
  };

  const handleDropdownClose = () => {
    setDropdownAnchor(null);
  };
  const hotelName = useMemo(() => {
    if (hotel && linked_rest?.length > 0) {
      // console.log("linkedddddd", linked_rest);
      const currentHotel = linked_rest?.find(
        (hot) => hot?.online_key === hotel?.online_key
      );
      // console.log("currentHotel", currentHotel);
      return currentHotel;
    } else {
      return hotel?.hotelName;
    }
  }, [hotel, linked_rest]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       dropdownRef?.current &&
  //       !dropdownRef?.current.contains(event.target) &&
  //       arrowButtonRef?.current &&
  //       !arrowButtonRef?.current.contains(event.target)
  //     ) {
  //       setDropdownAnchor(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const handleListItemClick = (onlineKey) => {
    // console.log("onlineeeee", onlineKey);
    history.push(`/${onlineKey?.online_key}`); // Update the URL with the new online key
    window.location.reload(); // Reload the page with the updated URL
  };

  const base64String = theme?.header_logo;
  const formatMatch = base64String?.match(
    /^data:image\/(png|jpeg|jpg);base64,/
  );

  if (formatMatch) {
    const fileFormat = formatMatch[1]; // Extract the file format (e.g., 'png')
    const fileName = `header_logo.${fileFormat}`; // Generate a filename dynamically
    console.log("filee", fileName); // Outputs 'header_logo.png'
  }

  // const isBase64 = theme?.header_logo?.startsWith("data:image");
  return (
    <div className={classes.container}>
      {theme?.header_logo && !isCart ? (
        // Otherwise, render the image with regular URL
        <div className={classes.headerLogoDiv}>
          <img
            src={`data:image/png;base64, ${theme?.header_logo}`}
            alt="Header Logo"
            className={classes.headerLogo}
          />
          {isHome && linked_rest?.length > 0 && (
            <ArrowDropDownCircleOutlined
              onClick={handleDropdownToggle}
              className={classes.dropdownIcon}
            />
          )}
        </div>
      ) : (
        <Typography
          className={isCart ? classes.cartTitle : clsx(classes.title)}
          // style={{ color: darkTheme ? "white" : "black" }}
          variant="h1"
          // noWrap
          display="block"
        >
          {/* {APP_NAME} */}
          {/* {hotel ? hotel.hotelName : ""} */}
          {hotelName?.name || hotelName}
          {isHome && linked_rest?.length > 0 && (
            <ArrowDropDownCircleOutlined
              onClick={handleDropdownToggle}
              className={classes.dropdownIcon}
              ref={arrowButtonRef}
            />
          )}
        </Typography>
      )}
      {/* <Menu
        anchorEl={dropdownAnchor}
        open={isDropdownOpen}
        // style={{ top: "40px", left: "113px" }}
        onClose={handleDropdownClose}
        keepMounted
      >
        {linked_rest?.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => {
              // Action when an item is selected
              handleDropdownClose();
            }}
          >
            <Typography variant="body1">{item.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {item.address}
            </Typography>
          </MenuItem>
        ))}
      </Menu> */}
      {!isMobile() && (
        <Typography
          className={clsx(
            isCart ? classes.locationSpl : classes.location
            // color === "black" && classes.blackColor
          )}
          variant="h6"
        >
          <LocationOnOutlinedIcon />
          {hotelAddress ? `${hotelAddress}` : ""}
        </Typography>
      )}

      {/* {dropdownAnchor && linked_rest?.length > 0 && (
        <div ref={dropdownRef} className={classes.dropdownContainer}>
          {linked_rest?.map(
            (lin) =>
              lin?.online_key !== hotelName?.online_key && (
                <div
                  key={lin.id}
                  className={classes.branchDiv}
                  onClick={() => handleListItemClick(lin)}
                >
                  <li key={lin.id} className={classes.list}>
                    {lin?.name}
                  </li>
                  <h6 className={classes.branchAddress}>{lin?.address}</h6>
                </div>
              )
          )}
        </div>
      )} */}
      {dropdownAnchor && linked_rest?.length > 0 && (
        <Modal
          open={dropdownAnchor}
          customView={true}
          isBodyScrollLock={true}
          modelContainer={
            isMobileScreen
              ? classes.branchesModalMobileContainer
              : classes.branchesModalContainer
          }
        >
          <div className={classes.branchModal}>
            <BranchHotels
              branches={linked_rest}
              handleListItemClick={handleListItemClick}
              setdropdownAnchor={setDropdownAnchor}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default HotelDetails;
