import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// Material
import { Alert, AlertTitle } from "@material-ui/lab";
// import Button from "@material-ui/core/Button";
// Custom Hooks
import useDynamicHotelData from "../../CustomHooks/useDynamicHotelData";
// Utils
import { dispatchResetErrorMessage } from "../../utils/Shared";
// Styles
import useStyles from "./style.js";
import { Button } from "@material-ui/core";

function ResturantMessageBar({ onActionClick = () => {} }) {
  const classes = useStyles();
  // const [open, setOpen] = useState(false);
  // const { hotel } = useSelector((state) => state.hotel);
  const error = useSelector((state) => state.error);

  const { hotelStatus, hotelOrderStatus } = useDynamicHotelData();

  // useEffect(() => {
  //   if (!hotelStatus.active) {
  //     setOpen(true);
  //   } else {
  //     setOpen(false);
  //   }
  // }, [hotelStatus]);

  useEffect(() => {
    // console.log("error?.noAutoClose", error?.noAutoClose);
    if (!error?.noAutoClose) {
      const timer = setTimeout(() => {
        dispatchResetErrorMessage();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [error?.noAutoClose]);

  return (
    <>
      {error.showAs === "Warning" && error.show && (
        <Alert
          variant="filled"
          severity={error.title === "Warning" ? "Warning" : "error"}
          classes={{ root: classes.root, message: classes.messageContainer }}
          onClose={() => {
            // setOpen(false);
            dispatchResetErrorMessage();
          }}
        >
          <div className={classes.alertDiv}>
            <AlertTitle>{error.title}</AlertTitle>
            {error.message}
          </div>
          {hotelOrderStatus && (
            <div className={classes.actionButtonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={
                  error?.title === "Warning"
                    ? classes.actionButton
                    : classes.errorActionButton
                }
                onClick={onActionClick}
              >
                See Opening Hours
              </Button>
            </div>
          )}
        </Alert>
      )}
    </>
  );
}
export default ResturantMessageBar;
